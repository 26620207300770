import type { Shop, User } from "@/types";
import { Heart } from "lucide-react";
import { useState } from "react";

interface Props {
  shop: Shop;
  user?: User | null;
}

const FavouritesButton = ({ shop, user }: Props) => {
  const [favoritesCount, setFavoritesCount] = useState(0);

  // TODO: navigate to favourited items
  return (
    <a href="/dashboard">
      <button
        className="relative p-2 hover:bg-kp-accent/10 rounded-full"
        aria-label="Favorites">
        <Heart className="w-6 h-6 text-kp-primary" />
        {/* <span className="absolute -top-1 -right-1 bg-kp-accent text-kp-black w-5 h-5 rounded-full text-xs flex items-center justify-center">
          {favoritesCount}
        </span> */}
      </button>
    </a>
  );
};

export default FavouritesButton;
